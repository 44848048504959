export default class Graph {
    constructor(selector) {
        this.selector = selector;
        this.el = document.querySelector(selector);

        this.init();
    }

    init() {

    }
}